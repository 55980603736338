import React from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AirportTransfers from "./Pages/AirportTransfers";
import About from "./Pages/About";
import Cars from "./Pages/Cars";
import LoginPassenger from "./Pages/LoginPassenger";
import LoginCaboffice from "./Pages/LoginCaboffice";
import SignupPassenger from "./Pages/SignupPassenger";
import SignupCaboffice from "./Pages/SignupCaboffice";
import { LogoutPassenger } from "./Pages/LogoutPassenger";
import AdminPanel from "./Components/layouts/AdminPanel";
import ProtectedRoute from "./Pages/ProtectedRoute";
import AdminLogin from "./Pages/AdminLogin";
import CabofficeDashboard from "./Components/layouts/CabofficeDashboard";
import ProtectedRoute2 from "./Pages/ProtectedRoute2";
import AdminPassenger from "./Components/layouts/AdminPassenger";
import CheckoutPage from "./Components/CheckoutPage";
import AdminCoveragePrice from "./Components/layouts/AdminCoveragePrice";
import AdminCabOffice from "./Components/layouts/AdminCabOffice";
import AdminBookJob from "./Components/layouts/AdminBookJob";
import CabOfficeProfile from "./Components/layouts/CabOfficeProfile";
import AdminPage from "./Components/layouts/AdminPage";
// import AdminJobs from "./Components/layouts/AdminJobs";
import AdminAllJob from "./Components/layouts/AdminAllJob";
import AdminInvoice from "./Components/layouts/AdminInvoice";
import CabOfficePage from "./Components/layouts/CabOfficePage";
import CabofficeAllJobs from "./Components/layouts/CabofficeAllJobs";
import CabOfficeViewjob from "./Components/layouts/CabOfficeViewjob";
import BlogCards from "./Components/BlogCards";
import CreateBlog from "./Components/layouts/CreateBlog";
import BlogDetail from "./Components/BlogDetail";
import CabofficeBlogs from "./Components/layouts/CabofficeBlogs";
import PassengerPanel from "./Components/layouts/PassengerPanel";
import PassengerProfile from "./Components/layouts/PassengerProfile";
import PassengerPage from "./Components/layouts/PassengerPage";
import ProtectedRoute3 from "./Pages/ProtectedRoute3";
import PassengerJobs from "./Components/layouts/PassengerJobs";
import PassengerBookJob from "./Components/layouts/PassengerBookJob";
import HeathrowAirport from "./Components/HeathrowAirport";
import SouthendAirport from "./Components/SouthendAirport";
import GatwickAirport from "./Components/GatwickAirport";
import StanstedAirport from "./Components/StanstedAirport";
import LutonAirport from "./Components/LutonAirport";
import LondonCitAirport from "./Components/LondonCitAirport";
import TermsCondition from "./Pages/TermsCondition";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CruiseSec1 from "./Components/CruiseSec1";
import ExecutiveTransfer from "./Components/ExecutiveTransfer";
import SightSeeing from "./Components/SightSeeing";
import TaxiService from "./Components/TaxiService";
import SiteMap from "./Pages/SiteMap";
import StationTransfer from "./Components/StationTransfer";
import Chauffeur from "./Pages/Chauffeur";
import HotelTransfer from "./Components/HotelTransfer";
import WeddingCar from "./Components/WeddingCar";
import SchoolRun from "./Components/SchoolRun";
import ThankYouPage from "./Components/ThankYouPage";

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/checkout" element={<CheckoutPage />}></Route>
	   <Route path="/thank-you" element={<ThankYouPage />} />
          <Route
            path="/airport-transfer"
            element={<AirportTransfers />}
          ></Route>
          <Route
            path="/airport-transfer/heathrow-airport"
            element={<HeathrowAirport />}
          ></Route>
          <Route
            path="/airport-transfer/southend-airport"
            element={<SouthendAirport />}
          ></Route>
          <Route
            path="/airport-transfer/gatwick-airport"
            element={<GatwickAirport />}
          ></Route>
          <Route
            path="/airport-transfer/stansted-airport"
            element={<StanstedAirport />}
          ></Route>
          <Route
            path="/airport-transfer/luton-airport"
            element={<LutonAirport />}
          ></Route>
          <Route
            path="/airport-transfer/londoncity-airport"
            element={<LondonCitAirport />}
          ></Route>
<Route path="/sitemap" element={<SiteMap />}></Route>
          <Route path="/about" element={<About />}></Route>
<Route path="/cruise-transfer" element={<CruiseSec1 />}></Route>
          <Route path="/executive-transfer" element={<ExecutiveTransfer />}></Route>
          <Route path="/sight-seeing" element={<SightSeeing />}></Route>
          <Route path="/taxi-service" element={<TaxiService />}></Route >
          <Route path="/station-transfer" element={<StationTransfer />}></Route >
<Route path="/terms-condition" element={<TermsCondition />}></Route>
<Route path="/chauffeur" element={<Chauffeur />}></Route>
          <Route path="/privacy" element={<PrivacyPolicy />}></Route>
          <Route path="/our-cars" element={<Cars />}></Route>
	   <Route path="/hotel-transfer" element={<HotelTransfer />}></Route>
          <Route path="/wedding" element={<WeddingCar />}></Route>
          <Route path="/school-run" element={<SchoolRun />}></Route>
          <Route path="/login-passenger" element={<LoginPassenger />}></Route>
          <Route path="/login-cab" element={<LoginCaboffice />}></Route>
          <Route path="/signup-passenger" element={<SignupPassenger />}></Route>
          <Route path="/logout-passenger" element={<LogoutPassenger />}></Route>
          {/* <Route path="/passengerpanel" element={<PassengerPanel />}></Route>
          <Route path="/passengerprofile" element={<PassengerProfile />}></Route> */}
          <Route path="/signup-cab" element={<SignupCaboffice />}></Route>
          <Route
            path="/passenger"
            element={
              <ProtectedRoute3>
                <PassengerPage />
              </ProtectedRoute3>
            }
          >
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<PassengerPanel />} />
            <Route path="profile" element={<PassengerProfile />} />
            <Route path="jobs" element={<PassengerJobs />} />
            <Route path="book_job" element={<PassengerBookJob />} />
            </Route>
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminPage />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<AdminPanel />} />
            <Route path="passengers" element={<AdminPassenger />} />
            <Route path="distanceprice" element={<AdminCoveragePrice />} />
            <Route path="cabofficer" element={<AdminCabOffice />}></Route>
            <Route path="bookjob" element={<AdminBookJob />}></Route>
            <Route path="jobs" element={<AdminAllJob />}></Route>
            <Route path="invoice" element={<AdminInvoice />}></Route>
            <Route path="createblog" element={<CreateBlog />} />
            <Route path="blogs" element={<BlogCards />} />
          </Route>
            <Route path="/blog/:id" element={<BlogDetail />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route
            path="/caboffice"
            element={
              <ProtectedRoute2>
                <CabOfficePage />
              </ProtectedRoute2>
            }
          >
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path="dashboard" element={<CabofficeDashboard />} />
            {/* <Route path="/caboffice/dashboard/:jobId" element={<CabOfficeViewjob />} /> */}
            <Route path="jobs" element={<CabofficeAllJobs />} />
            <Route path="blogs" element={<CabofficeBlogs />} />
            <Route path="profile" element={<CabOfficeProfile />}></Route>
          </Route>
          <Route path="/caboffice/jobs/:jobId" element={<CabOfficeViewjob />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

