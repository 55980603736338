import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Header from '../Components/Header'
import Section1 from '../Components/Section1'
import Section2 from '../Components/Section2'
import About from '../Components/About'
import Services from '../Components/Services'
import Testimonial from '../Components/Testimonial'
import Footer from '../Components/Footer'
import BlogCards from '../Components/BlogCards'
import UpperNavbar from '../Components/UpperNavbar'
import { Helmet } from 'react-helmet';

export default function Home() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (

    <div className='relative p-[0.1px]'>
<Helmet>
	<title>Minicaberz | Taxi service | Taxi near me | Uber taxi | Taxi service near me | Cabs near me | Cab company near me </title>
        <meta
          name="description"
          content="Experience the best cab services with our professional chauffeurs and luxury vehicles. Book now!"
        />
      </Helmet>
      <UpperNavbar />
      <Navbar />
      <Header />
      <Section1 />
      <Section2 />
      <About />
      <Services />
      <Testimonial />
      <BlogCards />
      <Footer />
    </div>
  )
}

