import React from 'react'
import { Link } from 'react-router-dom'

export default function TermsSec1() {
  return (
    <section className="w-[100%]" style={{ paddingBottom: 0 }}>
        <div className="w-[100%] mt-[80px]">
      <div className="w-[100%] h-[40vh] call-bg flex items-center justify-center">
          <p className="text-[50px] text-white font-semibold">Terms & Conditions</p>
        </div>
    </div>
  <div
    className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto pt-10 pb-10"
    style={{ flexDirection: "column", alignItems: "flex-start" }}
  >
    <p style={{ fontSize: 18 }}>
      If You wish to book a PRIVATE HIRE VEHICLE or TAXI (CAB) via this website,
      www.minicaberz.com, You must agree to the terms below as the exclusive
      basis which governs such booking and must tick the "I agree to minicaberz's
      Terms &amp; Conditions" box on the main passenger's contact information
      page. If You do not agree to any of the terms, do not book a PRIVATE HIRE
      VEHICLE or TAXI (CAB) via this website. If you are booking on behalf of
      someone else, by clicking "I agree to minicaberz's Terms &amp; Conditions",
      You are representing that You have their authority to accept these terms
      and conditions on their behalf.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>Definitions</p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      In these Terms and Conditions the following expressions shall have the
      following meanings save where the context otherwise requires:
    </p>
    <p style={{ fontSize: 18, marginLeft: 20, marginTop: 10 }}>
      <span style={{ fontWeight: 600 }}>1. Agreed Pick-Up Point</span> the
      appointed place for You to be picked up by PHO as set out in the Details.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>2. Agreed Time</span> the appointed date
      and time for You to be picked up by PHO as set out in the Details.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>3. Booking</span> the booking of a Cab
      effected via this Website for undertaking Trips. Bookings can be made for
      single Trips, return Trips or split Trips.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>4. PHO</span> the licensed Private Hire
      Operator or Taxi Operator with which You place the Booking.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>5. PHO T&amp;C</span> the terms and
      conditions of the PHO which in addition to these Terms govern the Booking
      and (Trip).
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>6. Card</span> the credit, debit or
      charge card, or PayPal account You use when making the Booking.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>7. Cash</span> the payment You make for
      the Fare to the PHO and/or its Driver.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>8. Details</span> the details which You
      must provide upon making a Booking including pick-up date and time,
      pick-up and drop-off addresses and Your Personal Data.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>9. Fare</span> the fee paid by You for
      the Quote You accept.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>10. Goods</span> any goods carried in a
      Cab which are booked via this Website.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>11. Trip</span> the journey You wish to
      make from the pick-up address to the drop-off address provided by You.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>12. Licensing Authority</span> any
      regional or national licensing authority responsible for licensing the
      operation of Private Hire Vehicle and/or taxi businesses.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>13. Particular Requirements</span> any
      specific requirements You may have relating to disabled facilities, the
      gender of the driver or otherwise.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>14. Passenger</span> any passenger
      carried in a Cab which is booked via this Website.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>15. Personal Data</span> the details
      which You provide regarding Your physical and email addresses, telephone
      number and Card details.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>16. Quote</span> a fixed price quote
      that You receive via the Website for the Trip.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>17. Terms</span> these terms and
      conditions between minicaberz and You.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>18 .Website</span> minicaberz's public
      website and mobile site located at www.minicaberz.com, and minicaberz's
      social media, smartphone and tablet apps.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>19. Cab</span> is a Private Hire Vehicle
      or (Hackney Carriage) Taxi made available for pre-booked Trips, in
      compliance with the requirements of relevant Licensing Authorities.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20 }}>
      The clause headings and any other headings are inserted for convenience
      only and shall not affect the construction of these Terms.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>
      USE OF WEBSITE
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      In making a Booking via this Website You must provide accurate Details and
      are responsible for any failure to do so. minicaberz acts as a disclosed
      booking agent for PHO, and is not an Operator itself. Notwithstanding the
      provisions of clause, the contract for the provision of the transport
      service is directly between You and PHO. Where in the Booking You have
      selected more that one PHO to provide Your Trips, then the contract for
      the provison of the transport service relating to each Trip is directly
      between You and the PHO You have selected for that Trip in the Booking.
      You hereby warrant to minicaberz that You are at least eighteen years of
      age and legally able to make the Booking. minicaberz does not guarantee
      that it will be able to fulfill Your requirements and reserves the rights
      to refuse any Bookings which You wish to make and to cancel any Bookings
      You have made, and in such case to refund to You, in full and final
      settlement of any claims you might have, the Fee paid by You. Your Details
      and data relating to Your use of the Website will be recorded by minicaberz
      but Your Personal Data shall not, subject to the provisions of the
      minicaberz Privacy Policy presented below and Clause 3.6 below, be
      disclosed to third parties other than the PHO or used for any purpose
      unrelated to the provision of the services pursuant hereto.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      minicaberz may send a small file to Your computer when You visit the
      Website. This "cookie" will enable minicaberz to track Your behaviour on
      the Website and to identify particular areas of interest so as to enhance
      Your future visits to the Website. The cookie will not enable minicaberz to
      identify You and minicaberz shall not use it otherwise than in relation to
      this Website. You can set Your computer browser to reject cookies but this
      may preclude use of certain parts of this Website. minicaberz may disclose
      Your Personal Data if compelled to do so by law, or at the request of a
      law enforcement agency or governmental authority. If You do not want
      minicaberz to use Your email address to send information concerning matters
      other than those related to Your Booking, You should send a message to
      info@minicaberz.com and insert "unsubscribe" as the subject heading. If you
      want to stop receiving minicaberz messages via WhatsApp concerning matters
      other than those related to Your Booking simply reply 'Stop' to the same
      WhatsApp number to opt out of receiving further messages.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>BOOKINGS</p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      When You enter the details of the Trip, minicaberz will endeavour to
      provide You via the Website with Quotes from one or more Private Hire
      and/or Taxi Operators that are able to fulfil Your requirement. The Quotes
      are the current special "one off" offers available from Private Hire
      Operators, which are only available at the time of making Your Booking. It
      is Your responsibility to ensure that Your Details are correct. When You
      make a Booking, this constitutes an offer which minicaberz may or may not
      accept. Your Booking is not confirmed until you see the confirmation
      screen on the Website. Any Booking You make may also be subject to PHO
      Terms &amp; Conditions, although where there is any conflict between the
      PHO Terms &amp; Conditions and the provisions of this agreement, the
      provisions contained in this agreement override those in the PHO Terms
      &amp; Conditions. If PHO wishes to impose PHO Terms &amp; Conditions on
      You, it shall be responsible for achieving this. minicaberz cannot confirm
      the ability of the PHO to accommodate any Particular Requirements and in
      the event that You have such Particular Requirements You should not use
      this Website but instead make alternative travel arrangements that address
      your Particular Requirements.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      minicaberz reserves the right to correct typographical errors in any
      elements of the information that appears on the Website including pricing
      mistakes. If, once You are informed of such error, You wish to withdraw
      Your offer and do not wish to proceed with the purchase, You shall be free
      to do so and any monies already paid by You will be refunded in full. You
      acknowledge and accept that minicaberz is acting as the agent of the PHO
      and that whilst minicaberz will try to assist with any complaints You may
      have in respect of the PHO's services, any legal remedy You seek will be
      sought against the PHO not minicaberz. For bookings that are to be pre-paid
      by Card, minicaberz is entitled to collect all monies due for the booked
      Trip from You acting entirely in its own right, and settle all Card Costs
      of the booked Trip, in advance of making the payment due to the PHO for
      the booked Trip as PHO booking agent. You agree that in the fulfillment of
      the Booking, where PHO feels it is appropriate, PHO gives minicaberz the
      right to novate the contract for the provision of transport services
      between You and PHO to another suitable party, to ensure the fulfillment
      of the Booking as effected via this Website.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>PAYMENT</p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      If booking with the "Pay by Card" option, You agree to pay the Fare at the
      time You make Your Booking. If booking with the "Pay by Cash" option, You
      agree to pay the Fare to the PHO's Driver. Once You have confirmed Your
      Booking on the payment screen, it cannot be cancelled or changed except in
      accordance with clause 7 of these Terms. If paying by Card, in the event
      that Your Card payment is refused by Your Card issuer for whatever reason,
      minicaberz will not be able to fulfill Your Booking. minicaberz will provide
      You with a receipt for Payment by email to the email address you
      registered with the booking. This will include a link to retrieve a VAT
      receipt for minicaberz's fees. You will need to contact the PHO directly
      for a VAT receipt relating to their fees.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      Payment will not cover any extra requirements you request, such as child
      seats. If you do not indicate any additional luggage when booking,
      resulting in a larger size vehicle being required, then the PHO may charge
      you extra, which you must pay them directly for. If You wish to give a tip
      to the Cab driver, You should do so directly at your own discretion. In
      the event that You make any deviations from the route that You entered
      into the Website, You may be subject to additional charges by PHO in
      respect thereof and will make such payment directly to PHO. For the
      avoidance of doubt, all Quotes provided by minicaberz are inclusive of VAT,
      where applicable, at the standard rate. Any promotional code applied to
      the Fare will first reduce the minicaberz fee. Any excess discount will
      then be deducted from the PHO-related fee. Only one promotional offer or
      prize can be applied to a booking, and only one entry per customer (as
      defined by their mobile number and/or email address) will be considered in
      any competition run by minicaberz. Where 2 or more promotional offers are
      requested to be applied to a booking, minicaberz at its discretion will
      apply the higher valued offer.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>
      CANCELLATION OF BOOKINGS AND REFUNDS
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      You have the right to cancel a Booking made through the Website. If You
      decide to cancel a Booking You must notify minicaberz by submitting a
      cancellation request via the form on the Contact Us page, quoting the
      booking reference, as per the guidance provided on the Website.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20, marginTop: 20 }}>
      1. In the event of the cancellation of a Booking in accordance with Clause
      7.1 above that is before the cancellation cut off time specified on the
      confirmation page and your booking confirmation email, then if paying by
      Card, You will be entitled to a full refund of the Fare (excluding any
      Card Costs). Where the cancellation is after the cancellation cut off time
      specified in the confirmation page and your booking confirmation email, if
      you paid by Card then no refund will be due to You, and if you were to pay
      by Cash, then your profile will be marked which may prevent you from
      booking through minicaberz again.
    </p>
    <p style={{ fontSize: 18, marginLeft: 20, marginTop: 20 }}>
      2. In the event of the cancellation of a Booking, for all vehicles
      carrying 9 or more passengers, in accordance with Clause 7.1 above, up to
      one week (unless otherwise specified on the confirmation page and your
      booking confirmation email) before the Agreed time, then if paying by
      Card, You will be entitled to a full refund of the Fare (excluding any
      Card Costs). Where the cancellation is less than one week, for all
      vehicles carrying 9 or more passengers (unless otherwise specified on the
      confirmation page and your booking confirmation email) before the agreed
      time, if you paid by Card then no refund will be due to You.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      In the event that PHO does not turn up at the Agreed Pick-Up Point within
      15 minutes of the Agreed Time and You do not use PHO for the Trip, You
      will be entitled to a full refund of the Fare, along with any Card Costs,
      if you paid them in advance by Card, as full and final settlement of any
      claim You might have in respect of such failure. However should You, at
      Your own discretion, decide to continue with the Trip where the PHO turns
      up after 15 minutes from the Agreed time, then you will not be entitled to
      any such refund.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      In the event that You are not available at the Agreed Pick-Up Point within
      15 minutes of the Agreed Time, it will be at PHO's discretion to treat
      Your non-availability as a cancellation, and You will not be entitled to
      any refund of the Fare , and any Card Costs that you may have paid in
      advance. Where the PHO decides to wait more than 15 minutes from the
      Agreed Time, then You may be subject to additional charges by PHO in
      respect of any additional waiting time and/or parking charges and will
      make such payment directly to PHO. For pre-paid bookings, any refunds due
      to you for cancellations or complaints will be made to the card used to
      make the payment within upto 3-5 business days (business days being Monday
      to Friday), depending on your bank or card issuer.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>
      WARRANTY AND LIMITATION OF LIABILITY
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      PHO has warranted to minicaberz that it is licensed by the appropriate
      Licensing Authority(ies) but minicaberz shall not be responsible for
      verification of such warranty and if You are in any doubt, You should
      contact the Private Hire or Taxi Operator and rely on Your own
      verification. minicaberz shall not be liable for any delays in pick-up or
      drop-off times or for any failure of PHO to arrive at all or for any
      failure of PHO to provide its services in accordance with Your
      requirements or expectations. minicaberz shall not in any event be liable
      (whether in contract or otherwise) for any indirect loss or consequential
      loss or loss of revenue, howsoever arising, suffered by You in connection
      with any failure by PHO or otherwise in connection with these Terms or
      otherwise and if You consider that You have any claim in respect of any
      failure by PHO You agree to make such claim directly against PHO.
      minicaberz's aggregate liability in respect of these Terms and any matter
      arising out of it (including claims whether made in contract or tort)
      shall be limited to £500. Subject as expressly provided in these Terms all
      warranties, conditions or other terms implied by statute or common law are
      excluded as far as legally possible.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      Nothing in this clause shall operate so as to exclude any rights You may
      have under consumer legislation or limit PHO's liability for death or
      personal injury arising out of its negligence. Any estimated trip times
      and times of arrival presented by minicaberz on its Public website or
      otherwise, are only to be taken as indicative, and are not subject to
      traffic and/or weather conditions and final route taken by driver.
      Therefore, You agree that You are responsible for setting an appropriate
      Agreed Time that allows You sufficient time to reach Your destination to
      subsequently check-in for a flight, take a train or fulfill any other
      dependencies You might have. minicaberz may keep You informed of the status
      of your Booking by sending You email, and SMS text alerts, although
      minicaberz does not guarantee the delivery nor the timing of these email
      and SMS text alerts, and shall not in any event be liable (whether in
      contract or otherwise) for any indirect loss or consequential loss or loss
      of revenue, howsoever arising from the omission or delay of such email and
      SMS text alerts. Each foregoing sub-clause of this clause shall constitute
      a separate and severable provision. The provisions of this clause shall
      continue in effect notwithstanding the termination, completion or any
      other matter which might otherwise cause these Terms to become
      ineffective.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>DISPUTES</p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      If You have any complaint in respect of the service provided by PHO, You
      agree to first contact PHO directly (by phone or email) to resolve this
      with them within 24 hours of the Agreed Time. If you are subsequently
      unable to resolve your complaint with PHO, you agree to notify minicaberz
      thereof via the form on the Contact Us page or by email to
      info@minicaberz.com within 72 hours of the Agreed Time. In the event that
      You fail to do so, any claim You might otherwise have shall be
      invalidated. If minicaberz is then unable to resolve any claim You may have
      in respect of the service provided by PHO to the mutual satisfaction of
      You and PHO, minicaberz shall be entitled to submit such claim to the
      determination of an independent third party selected by minicaberz and You
      agree to be bound by the decision of such third party. Should You have a
      dispute with the PHO, minicaberz is not liable for any claims, demands and
      damages (actual and consequential) of any kind and nature arising out of
      or in any way connected with such disputes.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>
      PRIVACY POLICY
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      minicaberz will maintain the confidentiality of Personal Data collected as
      part of making a Booking. This data will not be shared with third parties
      without Your consent. minicaberz may amend this Privacy Policy, and will
      make such changes public via the Website.
    </p>
    <p style={{ fontSize: 24, fontWeight: 600, marginTop: 20 }}>
      MISCELLANEOUS
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      These Terms shall form the entire agreement between the parties and
      supersede any previous agreement and (save so far as expressly preserved
      hereby) representations oral or otherwise made by either of the parties.
      You warrant that You have not relied on any representation made by
      minicaberz in entering into this agreement. Failure by either party at any
      time to enforce any right claim or provision of these Terms or arising
      hereunder shall not be construed as a waiver of such right, claim or
      provision. All notices to minicaberz shall be given by You in writing to
      info@minicaberz.com. All notices to You shall be given in writing to the
      email address You have provided as part of the Details. All notices may be
      served by email and shall be deemed to have been given 1 hour after
      transmission thereof. These Terms and Your agreement with minicaberz shall
      be governed and construed in accordance with English Law. You irrevocably
      agree for the exclusive benefit of minicaberz to submit any dispute
      hereunder to the jurisdiction of the courts of England but nothing herein
      shall prevent minicaberz from taking proceedings against You in any other
      court of competent jurisdiction.
    </p>
  <div className="w-[100%] pt-5 pb-5 text-center call-bg">
        <p class="text-[40px] font-semibold text-white">
            CALL 020 8050 8797 OR BOOK ONLINE
        </p>
        <p class="text-[18px] mt-6 text-white">
            Always Pre Book Your Airport Trasnfer Taxi To Get Hassle Free Service
        </p>
        <Link to={"/"}>
        <button class="bg-[white] rounded-md pl-6 pr-6 pt-2 pb-2 mt-3">BOOK NOW</button>
        </Link>
        </div>
        <section class="w-[100%] pb-8">
      {/* <div class="section4-container"> */}
        <p class="text-[30px] text-center font-semibold mt-8">Why Choose minicaberz for Your Heathrow Airport Transfer</p>
        <div class="flex flex-wrap justify-center md:justify-between mt-8">
          <div class="w-[100%] sm:w-[95%] md:w-[48%]">
            <p class="text-[18px]">
              Looking for a dependable, affordable taxi service in London?
              minicaberz offers
              <span className="font-semibold">24/7 airport transfers</span> and
              <span className="font-semibold">minicab services</span>
              across London, including Heathrow, Gatwick, Stansted, and other
              major airports. With minicaberz, you get safe, comfortable rides at
              fixed rates—no hidden charges!
            </p>
            <p class="text-[18px] mt-4">
              Ready to ride?
              <span className="font-semibold"
                >Book your London airport taxi with minicaberz now!</span
              >
              Whether it’s for business, a family trip, or a quick transfer,
              we’ve got you covered. Visit minicaberz.co.uk to schedule your next
              ride. We provide round-the-clock airport transfer services across
              all London airports, ensuring you reach your destination on time,
              day or night.
            </p>
          </div>
          <div class="w-[100%] sm:w-[95%] md:w-[48%]">
            <p class="text-[18px]">
              <span className="font-semibold">Professional Drivers:</span> Our
              experienced, courteous drivers are ready to pick you up any time,
              day or night.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Easy Online Booking:</span> Simply
              book online for a smooth experience—no waiting, no hassle.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Affordable Rates:</span> We believe
              in transparency; all fares are fixed and affordable.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Reliable & Punctual:</span> Trust
              minicaberz to get you to your destination on time, every time.
            </p>
          </div>
        </div>
      {/* </div> */}
    </section>
  </div>
</section>

  )
}

