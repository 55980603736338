import React, { useState } from "react";

function CabOfficerDetailsModal({ officer, onClose, onSave }) {
  const [editableOfficer, setEditableOfficer] = useState({ ...officer });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableOfficer((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditableOfficer((prev) => ({
        ...prev,
        [name]: checked,
      }));
  };

  const handleSave = () => {
    onSave(editableOfficer);
  };

  return (
    <div className="absolute top-0 left-0 bg-gray-600 bg-opacity-50 pt-[100px] pb-[50px] flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-[90%] h-[max-content]">
        <h2 className="text-4xl text-center font-semibold mb-4">Cab Office Registration</h2>
        <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    CAB OFFICE DETAIL
                  </p>
                  <div className="flex justify-between">

                  <div className="flex flex-col mb-6 w-[48%]">
                  <label
                      for="Correspondence/Owner"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Principal Contact for Correspondence/Owner
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="owner"
                        value={editableOfficer.owner}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Correspondence/Owner"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mb-6 w-[48%]">
                  <label
                      for="Correspondence/Owner"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Cab Office Status
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="isApproved"
                        value={editableOfficer.isApproved}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Correspondence/Owner"
                        disabled
                      />
                    </div>
                  </div>
                    </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="cab-office-name"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Cab Office Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="cab_office_name"
                          value={editableOfficer.cab_office_name}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Cab Office Name"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="system_name"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Dispatch System Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="system_name"
                          value={editableOfficer.system_name}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="System Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="phone_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Phone
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="phone_caboffice"
                          value={editableOfficer.phone_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Cab Office Phone"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="fax_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Fax
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="fax_caboffice"
                          value={editableOfficer.fax_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Fax"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="email"
                          name="email_caboffice"
                          value={editableOfficer.email_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mb-6">
                    <label
                      for="adress_caboffice"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Cab Office Address
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="address_caboffice"
                        value={editableOfficer.address_caboffice}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Adress"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="city_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        City
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="city_caboffice"
                          value={editableOfficer.city_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="post_code"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Post Code
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="postcode_caboffice"
                          value={editableOfficer.postcode_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="date_buisness_commenced"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Date Business Commenced
                      </label>
                      <div className="relative">
                        <input
                
                          type="date"
                          name="date_caboffice"
                          value={editableOfficer.date_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="license_number"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        License Number
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="licenseNo_caboffice"
                          value={editableOfficer.licenseNo_caboffice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="License No"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for=""
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Business Type
                      </label>
                      <select
                        name="selectOption_caboffice"
                        value={editableOfficer.selectOption_caboffice}
                        onChange={handleChange}
                        className="relative text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                      >
                        <option value="sole-proprietorship">
                          Sole Proprietorship
                        </option>
                        <option value="partnership">Partnership</option>
                        <option value="corporation">Corporation</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex items-start justify-between mb-6">
                    <div className="flex items-center w-[48%]">
                      <p className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 font-semibold">
                        Do you Accept Direct Cards Payments from Passengers :
                      </p>
                      <input
                        className="ml-8"
                        type="checkbox"
                        name="d_paypassenger"
                        checked={editableOfficer.d_paypassenger}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="flex items-center w-[48%]">
                      <div className="">
                        <p className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 font-semibold">
                          Would you like Account Jobs with minicabers :
                        </p>
                        <p className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                          Note: Account amount is paid after the end of month.
                        </p>
                      </div>
                      <input
                        className="ml-8"
                        type="checkbox"
                        name="accountJobs_caboffice"
                        checked={editableOfficer.accountJobs_caboffice}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mt-3 mb-2">
                  <p className="text-[25px] font-semibold mb-6">FLEET DETAIL</p>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_saloon"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of Saloon
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_saloon"
                          value={editableOfficer.no_of_saloon}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Number of Saloon"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_estate"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of Estate
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_estate"
                          value={editableOfficer.no_of_estate}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Number of Estate"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_mpv"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of MPV
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_mpv"
                          value={editableOfficer.no_of_mpv}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Number of MPV"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_othervehicles"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of other Vehicles
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_othervehicles"
                          value={editableOfficer.no_of_othervehicles}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Others"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* //////////////////////////////////Invoice Detail////////////////////////////////////////////////// */}

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    INVOICE DETAIL
                  </p>
                  <div className="flex flex-col mb-6">
                    <label
                      for="adress"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Invoice Address
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="address_invoice"
                        value={editableOfficer.address_invoice}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Adress"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="city"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        City
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="city_invoice"
                          value={editableOfficer.city_invoice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="post_code"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Post Code
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="postcode_invoice"
                          value={editableOfficer.postcode_invoice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mb-6">
                    <label
                      for="Correspondence/Owner"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Principal Contact for Correspondence
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="owner_invoice"
                        value={editableOfficer.owner_invoice}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Contact Correspondence"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="phone-caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Phone
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="phone_invoice"
                          value={editableOfficer.phone_invoice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="fax"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Fax
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="fax_invoice"
                          value={editableOfficer.fax_invoice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Fax"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="email"
                          name="email_invoice"
                          value={editableOfficer.email_invoice}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    AUTHORISED CONTROLLERS/USERS FOR LOGINS
                  </p>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email_authController"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="email_authController"
                          value={editableOfficer.email_authController}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="pass_authController"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="pass_authController"
                          value={editableOfficer.pass_authController}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_confirmpassCont"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Confirm Password
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_confirmpassCont"
                          value={editableOfficer.auth_confirmpassCont}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_fname"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        First Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_fname"
                          value={editableOfficer.auth_fname}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_lname"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Last Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_lname"
                          value={editableOfficer.auth_lname}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_mobile"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Mobile
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_mobile"
                          value={editableOfficer.auth_mobile}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Mobile"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    BUSINESS/TRADE REFERENCE
                  </p>
                  <div className="flex flex-col mb-6">
                    <label
                      for="company_name"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Company/Business Name
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="company_name"
                        value={editableOfficer.company_name}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Company Name"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col mb-6">
                    <label
                      for="buis_adress"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Address
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="buis_address"
                        value={editableOfficer.buis_address}
                        onChange={handleChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Adress"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="buis_city"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        City
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_city"
                          value={editableOfficer.buis_city}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[48%]">
                      <label
                        for="buis_post_code"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Post Code
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_postcode"
                          value={editableOfficer.buis_postcode}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="phone-caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Phone
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_phone"
                          value={editableOfficer.buis_phone}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Cab Office Phone"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="fax"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Fax
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_fax"
                          value={editableOfficer.buis_fax}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Fax"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="email"
                          name="buis_email"
                          value={editableOfficer.buis_email}
                          onChange={handleChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    TERMS AND CONDITIONS
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    1. Cab Office will be fully responsible for serving a Job
                    once it has been booked or assigned on website in result of
                    settings, failures or delays in job will result into
                    termination of relationship between minicabers and partner
                    Cab Office.
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    2. Cab Office will ensure accurate settings (waiting times,
                    freezing and rates) on website all the times for smooth Job
                    handling. Same minicabers fee will be applicable in case of
                    failures and delays in assigned jobs.
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    3. 10% fee of total job amount will be charged by minicabers
                    in every job booked or assigned (Cash and Account) to cab
                    office on monthly basis and will be payable within 7 days of
                    Invoice
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    4. itLogi Solutions LTD is trading as minicabers is not a
                    licensed private hire operator. minicabers services are
                    acting as a Booking Agent.
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    5. All Standard Contracts and Payment terms are included as
                    per UK law.
                  </p>
                  <p className="border-t-2 border-gray-400 mt-5 mb-5"></p>
                  <div className="flex items-center mb-2">
                    <input
                      className="ml-2 mr-3"
                      type="checkbox"
                      name="agreeTerms"
                      checked={editableOfficer.agreeTerms}
                      onChange={handleCheckboxChange}
                    />
                    <p className="text-[16px]">
                      <span className="font-semibold">I agree</span> to be bound
                      by the terms and conditions.
                    </p>
                  </div>
                  <div className="flex items-center mb-6">
                    <input
                      className="ml-2 mr-3"
                      type="checkbox"
                      name="agreeCreditCheck"
                      checked={editableOfficer.agreeCreditCheck}
                      onChange={handleCheckboxChange}
                    />
                    <p className="text-[16px]">
                      <span className="font-semibold">I agree</span> that this
                      application form may be used as part of a credit check
                      undertaken by minicaberz and/or their nominated agents.
                    </p>
                  </div>
                </div>
        <div className="flex justify-end">
          <button
            onClick={onClose}
            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
          >
            Close
          </button>
          <button
            onClick={handleSave}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default CabOfficerDetailsModal;

