import React from 'react'
import { Link } from 'react-router-dom'

export default function PrivacySec1() {
  return (
    <section className="w-[100%]" style={{ paddingBottom: 0 }}>
  <div className="w-[100%] mt-[80px]">
      <div className="w-[100%] h-[40vh] call-bg flex items-center justify-center">
          <p className="text-[50px] text-white font-semibold">Privacy Policy</p>
        </div>
    </div>
  <div
    className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto pt-10 pb-10"
    style={{ flexDirection: "column", alignItems: "flex-start" }}
  >
    <p style={{ fontSize: 18 }}>
      We respect the privacy rights of our online visitors and recognise the
      importance of protecting your personal information. We have adopted a
      Privacy Policy that guides how we collect, store, and use the information
      that you provide us with.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      The following information is provided by minicaberz to enable our online
      visitors to be fully informed of our Privacy Policies. By visiting
      www.minicaberz.com, you are accepting and consenting to the practices
      described in this Privacy Policy and the Terms and Conditions.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      This Privacy Policy applies to websites maintained by other companies or
      organisations from which we link. However, this Privacy Policy does not
      apply to websites maintained by other companies or organisations to which
      we link and minicaberz is not responsible for any personal information you
      submit to third parties via our website. Please ensure that you read the
      Privacy Policy of such other companies or organisations before submitting
      your details.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      What personal information do we collect from you and how?
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      minicaberz collects personal information in several ways when you make a
      booking or register for a service offered by minicaberz. By registering,
      you are consenting to the collection of your personal data. If you book a
      journey with us, we need to hold personal information including your name,
      email address, phone numbers, pickup address, destination address and
      credit/debit card billing address(es) so that we can process and fulfill
      your booking. Saved card details will never be shared with third parties
      and will only be used to process your order, using our Payment Service
      Provider's systems.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      We may also obtain information as a result of authentication or identity
      checks. We will ask for your telephone number. Only required personal
      information is given the the PHO (Private Hire Operator) in order to
      fulfill your booking.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      What about cookies?
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      Our site uses cookies (very small files that are sent by us to your
      computer or other access device) which we can access when you visit our
      site in future.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      There are three types of cookies:
    </p>
    <p style={{ fontSize: 18, marginTop: 20, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>
        1. Website functionality cookies :
      </span>
      These cookies enable you to browse the website and use our features such
      as using a registered account and booking journeys.
    </p>
    <p style={{ fontSize: 18, marginTop: 10, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>2. Website analytics cookies :</span>We
      use these cookies to measure and analyse how our customers use the
      website. This allows us to continuously improve our website and your
      booking experience.
    </p>
    <p style={{ fontSize: 18, marginTop: 10, marginLeft: 20 }}>
      <span style={{ fontWeight: 600 }}>3. Customer preference cookies :</span>
      When browsing, the website will remember preferences you make (for example
      remembering your login, language or location). This makes your browsing
      experience simpler, easier and more personal to you.
    </p>
    <p style={{ fontSize: 18, marginTop: 20, fontWeight: 600 }}>
      By using our website you agree that we can place these types of cookies on
      your device and access them when you visit the site in the future.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      If you want to delete any cookies that are already on your computer,
      please refer to the help and support area on your internet browser for
      instructions on how to locate the file or directory that stores cookies.
      Information on deleting or controlling cookies is available at
      www.AboutCookies.org. Please note that by deleting our cookies or
      disabling future cookies, you may not be able to access certain areas or
      features of our site.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      How this information may be used, including anyone it might be shared with
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      minicaberz may use your personal information for the processing of orders,
      payments, to manage your account and to provide you with a personalised
      booking experience. We will also share your [name, telephone number and
      pick up location] with the third party cab operators who will fulfil your
      booking. Personal information that you provide may be disclosed to a fraud
      prevention agency, which may keep a record of that information. Where
      there is a legal obligation to do so, we may disclose your information to
      any relevant regulatory body.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      We may also use your personal information to send you marketing updates
      but only ever in accordance with your preferences (as detailed in the next
      section). We do not sell your details to third party marketing companies.
      We do collate information about site traffic, bookings and other
      commercial information which we may pass on to third parties but this
      information does not include any information which can identify you
      personally.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      Finally, we may use your personal information for our internal marketing
      and demographic studies, together with non-personal data to monitor
      customer patterns so we can consistently improve our site design to better
      meet our visitors' needs.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      From time to time minicaberz may employ third party business partners to
      collect personal information or to undertake marketing activities on
      minicaberz's behalf. In such cases, these third parties will be subject to
      confidentiality agreements and instructed by minicaberz to comply fully
      with the minicaberz Privacy Policy.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      Marketing opt-in and opt-out provision
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      When you book, you will be given the option to opt-out of subscribing to
      our regular update service which may send you:
    </p>
    <p style={{ fontSize: 18, marginTop: 20, marginLeft: 20 }}>
      Email alerts for new products, features, enhancements, special offers,
      upgrade opportunities, contests, events of interest, and one-off marketing
      promotions. On occasion, direct mail alerts for new products, features,
      enhancements, special offers, upgrade opportunities, contests, events of
      interest, and one-off marketing promotions.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      We do not sell or otherwise pass your details to third parties for
      marketing purposes. Marketing communications you subscribe to will only be
      sent by minicaberz.
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      At all times, we will offer you the opportunity to unsubscribe out of any
      service or update to which you have subscribed, if you change your mind.
      Any e-mail we send you will contain an easy automated unsubscribe link so
      that you can opt-out of that particular mailshot. Simply follow the
      indications in the e-mail.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      How you can access and, if necessary, change the personal information
      minicaberz maintains
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      If for any reason you are concerned that the personal information held by
      minicaberz is not correct, please use the âContact Usâ of our website or
      app, stating your email that has been used in your booking or in your
      registered Account.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      Safeguarding your personally identifiable information
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      We will take reasonable steps to maintain appropriate safeguards to ensure
      the security, integrity and privacy of the information you have provided
      us with. When you make a booking or access your account information, we
      use Secure Socket Layer (SSL) encryption which encrypts your information
      before it is sent to us, to protect it from unauthorised use. In addition,
      we will take reasonable steps to ensure that third party business partners
      to whom we transfer any data will provide sufficient protection of that
      personal information.
    </p>
    <p style={{ fontSize: 24, marginTop: 20, fontWeight: 600 }}>
      Where and how to ask questions or file complaints
    </p>
    <p style={{ fontSize: 18, marginTop: 20 }}>
      If you require further information about our Privacy Policy, please go to
      the âContact Usâ section of the website or app, or email us directly at
      info@minicaberz.com
    </p>
  <div className="w-[100%] pt-5 pb-5 mt-8 text-center call-bg">
        <p class="text-[40px] font-semibold text-white">
            CALL 020 8050 8797 OR BOOK ONLINE
        </p>
        <p class="text-[18px] mt-6 text-white">
            Always Pre Book Your Airport Trasnfer Taxi To Get Hassle Free Service
        </p>
        <Link to={"/"}>
        <button class="bg-[white] rounded-md pl-6 pr-6 pt-2 pb-2 mt-3">BOOK NOW</button>
        </Link>
        </div>
        <section class="w-[100%] pb-8">
      {/* <div class="section4-container"> */}
        <p class="text-[30px] text-center font-semibold mt-8">Why Choose minicaberz for Your Heathrow Airport Transfer</p>
        <div class="flex flex-wrap justify-center md:justify-between mt-8">
          <div class="w-[100%] sm:w-[95%] md:w-[48%]">
            <p class="text-[18px]">
              Looking for a dependable, affordable taxi service in London?
              minicaberz offers
              <span className="font-semibold">24/7 airport transfers</span> and
              <span className="font-semibold">minicab services</span>
              across London, including Heathrow, Gatwick, Stansted, and other
              major airports. With minicaberz, you get safe, comfortable rides at
              fixed rates—no hidden charges!
            </p>
            <p class="text-[18px] mt-4">
              Ready to ride?
              <span className="font-semibold"
                >Book your London airport taxi with minicaberz now!</span
              >
              Whether it’s for business, a family trip, or a quick transfer,
              we’ve got you covered. Visit minicaberz.co.uk to schedule your next
              ride. We provide round-the-clock airport transfer services across
              all London airports, ensuring you reach your destination on time,
              day or night.
            </p>
          </div>
          <div class="w-[100%] sm:w-[95%] md:w-[48%]">
            <p class="text-[18px]">
              <span className="font-semibold">Professional Drivers:</span> Our
              experienced, courteous drivers are ready to pick you up any time,
              day or night.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Easy Online Booking:</span> Simply
              book online for a smooth experience—no waiting, no hassle.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Affordable Rates:</span> We believe
              in transparency; all fares are fixed and affordable.
            </p>
            <p class="text-[18px] mt-3">
              <span className="font-semibold">Reliable & Punctual:</span> Trust
              minicaberz to get you to your destination on time, every time.
            </p>
          </div>
        </div>
      {/* </div> */}
    </section>
  </div>
</section>

  )
}

