import React, { useState,useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import axios from "axios";
import { Link,useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import UpperNavbar from "../Components/UpperNavbar";

export default function SignupCaboffice() {
  const [formData, setFormData] = useState({
    owner: "",
    cab_office_name: "",
    system_name: "",
    phone_caboffice: "",
    fax_caboffice: "",
    email_caboffice: "",
    address_caboffice: "",
    city_caboffice: "",
    postcode_caboffice: "",
    date_caboffice: "",
    licenseNo_caboffice: "",
    selectOption_caboffice: "sole-proprietrship",
    d_paypassenger: false,
    accountJobs_caboffice: false,
    no_of_saloon: "",
    no_of_estate: "",
    no_of_mpv: "",
    no_of_othervehicles: "",
    address_invoice: "",
    city_invoice: "",
    postcode_invoice: "",
    owner_invoice: "",
    phone_invoice: "",
    fax_invoice: "",
    email_invoice: "",
    email_authController: "",
    pass_authController: "",
    auth_confirmpassCont: "",
    auth_fname: "",
    auth_lname: "",
    auth_mobile: "",
    company_name: "",
    buis_address: "",
    buis_city: "",
    buis_postcode: "",
    buis_phone: "",
    buis_fax: "",
    buis_email: "",
    agreeTerms: false,
    agreeCreditCheck: false,
  });

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      await axios.post('https://api.minicaberz.com/api/caboffice/register', formData);
      toast.success('Registration successfull');
      navigate('/');
    } catch (error) {
      alert('Registration failed: ' + error.response?.data?.message || error.message);
    }
  };
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="relative w-[100%] bg-[#D1D5DB] p-[0.1px]">
      <UpperNavbar />
      <Navbar />
      <div className="container flex items-center justify-center mt-[80px] pt-[50px] pb-[50px]">
        <div className="w-[95%] flex flex-col items-center justify-center bg-gray-300">
          <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
            <div className="font-medium self-center text-xl sm:text-2xl uppercase text-gray-800">
              Cab Office Registration
            </div>

            <div className="mt-10">
              <form action="#" onSubmit={handleSubmit}>
                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    CAR OFFICE DETAIL
                  </p>
                  <div className="flex justify-between">
                  <div className="flex flex-col mb-6 w-[32%]">
                    <label
                      for="Correspondence/Owner"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Principal Contact for Correspondence/Owner
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="owner"
                        value={formData.owner}
                        onChange={handleInputChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Correspondence/Owner"
                      />
                    </div>
                  </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="cab-office-name"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Cab Office Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="cab_office_name"
                          value={formData.cab_office_name}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Cab Office Name"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="system_name"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Dispatch System Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="system_name"
                          value={formData.system_name}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="System Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="phone_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Phone
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="phone_caboffice"
                          value={formData.phone_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Cab Office Phone"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="fax_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Fax
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="fax_caboffice"
                          value={formData.fax_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Fax"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="email"
                          name="email_caboffice"
                          value={formData.email_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>


                  <div className="flex justify-between">
                  <div className="flex flex-col mb-6 w-[32%]">
                    <label
                      for="adress_caboffice"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Cab Office Address
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="address_caboffice"
                        value={formData.address_caboffice}
                        onChange={handleInputChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Adress"
                      />
                    </div>
                  </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="city_caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        City
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="city_caboffice"
                          value={formData.city_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="post_code"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Post Code
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="postcode_caboffice"
                          value={formData.postcode_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="date_buisness_commenced"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Date Business Commenced
                      </label>
                      <div className="relative">
                        <input
                
                          type="date"
                          name="date_caboffice"
                          value={formData.date_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="license_number"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        License Number
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="licenseNo_caboffice"
                          value={formData.licenseNo_caboffice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="License No"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for=""
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Business Type
                      </label>
                      <select
                        name="selectOption_caboffice"
                        value={formData.selectOption_caboffice}
                        onChange={handleInputChange}
                        className="relative text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                      >
                        <option value="sole-proprietorship">
                          Sole Proprietorship
                        </option>
                        <option value="partnership">Partnership</option>
                        <option value="corporation">Corporation</option>
                        <option value="others">Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="flex items-start justify-between mb-6">
                    <div className="flex items-center w-[48%]">
                      <p className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 font-semibold">
                        Do you Accept Direct Cards Payments from Passengers :
                      </p>
                      <input
                        className="ml-8"
                        type="checkbox"
                        name="d_paypassenger"
                        checked={formData.d_paypassenger}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="flex items-center w-[48%]">
                      <div className="">
                        <p className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600 font-semibold">
                          Would you like Account Jobs with minicaberz :
                        </p>
                        <p className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600">
                          Note: Account amount is paid after the end of month.
                        </p>
                      </div>
                      <input
                        className="ml-8"
                        type="checkbox"
                        name="accountJobs_caboffice"
                        checked={formData.accountJobs_caboffice}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mt-3 mb-2">
                  <p className="text-[25px] font-semibold mb-6">FLEET DETAIL</p>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_saloon"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of Saloon
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_saloon"
                          value={formData.no_of_saloon}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Number of Saloon"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_estate"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of Estate
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_estate"
                          value={formData.no_of_estate}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Number of Estate"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_mpv"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of MPV
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_mpv"
                          value={formData.no_of_mpv}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Number of MPV"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[24%]">
                      <label
                        for="no_of_othervehicles"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Number of other Vehicles
                      </label>
                      <div className="relative">
                        <input
                
                          type="number"
                          name="no_of_othervehicles"
                          value={formData.no_of_othervehicles}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Others"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* //////////////////////////////////Invoice Detail////////////////////////////////////////////////// */}

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    INVOICE DETAIL
                  </p>
                  <div className="flex flex-col mb-6">
                    <label
                      for="adress"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Invoice Address
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="address_invoice"
                        value={formData.address_invoice}
                        onChange={handleInputChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Adress"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="city"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        City
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="city_invoice"
                          value={formData.city_invoice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="post_code"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Post Code
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="postcode_invoice"
                          value={formData.postcode_invoice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                  <div className="flex flex-col mb-6 w-[32%]">
                    <label
                      for="Correspondence/Owner"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Principal Contact for Correspondence
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="owner_invoice"
                        value={formData.owner_invoice}
                        onChange={handleInputChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Contact Correspondence"
                      />
                    </div>
                  </div>
                  </div>


                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="phone-caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Phone
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="phone_invoice"
                          value={formData.phone_invoice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Phone"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="fax"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Fax
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="fax_invoice"
                          value={formData.fax_invoice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Fax"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="email"
                          name="email_invoice"
                          value={formData.email_invoice}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    AUTHORISED CONTROLLERS/USERS FOR LOGINS
                  </p>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email_authController"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="email_authController"
                          value={formData.email_authController}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="pass_authController"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Password
                      </label>
                      <div className="relative">
                        <input
                
                          type="password"
                          name="pass_authController"
                          value={formData.pass_authController}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Password"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_confirmpassCont"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Confirm Password
                      </label>
                      <div className="relative">
                        <input
                
                          type="password"
                          name="auth_confirmpassCont"
                          value={formData.auth_confirmpassCont}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Confirm Password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_fname"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        First Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_fname"
                          value={formData.auth_fname}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_lname"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Last Name
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_lname"
                          value={formData.auth_lname}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="auth_mobile"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Mobile
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="auth_mobile"
                          value={formData.auth_mobile}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Mobile"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    BUSINESS/TRADE REFERENCE
                  </p>
                  

                  

                  <div className="flex justify-between">
                  <div className="flex flex-col mb-6 w-[32%]">
                    <label
                      for="company_name"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Company/Business Name
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleInputChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Company Name"
                      />
                    </div>
                  </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="buis_city"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        City
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_city"
                          value={formData.buis_city}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="buis_post_code"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Post Code
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_postcode"
                          value={formData.buis_postcode}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Post Code"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col mb-6">
                    <label
                      for="buis_adress"
                      className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                    >
                      Address
                    </label>
                    <div className="relative">
                      <input
              
                        type="text"
                        name="buis_address"
                        value={formData.buis_address}
                        onChange={handleInputChange}
                        className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                        placeholder="Adress"
                      />
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="phone-caboffice"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Phone
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_phone"
                          value={formData.buis_phone}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Cab Office Phone"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="fax"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Fax
                      </label>
                      <div className="relative">
                        <input
                
                          type="text"
                          name="buis_fax"
                          value={formData.buis_fax}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Fax"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col mb-6 w-[32%]">
                      <label
                        for="email"
                        className="mb-1 text-xs sm:text-sm tracking-wide text-gray-600"
                      >
                        Email
                      </label>
                      <div className="relative">
                        <input
                
                          type="email"
                          name="buis_email"
                          value={formData.buis_email}
                          onChange={handleInputChange}
                          className="text-sm sm:text-base placeholder-gray-500 pl-3 pr-4 rounded-lg border border-gray-400 w-full py-2 focus:outline-none focus:border-blue-400"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                

                <div className="border-t-2 rounded-md border-[#2953A1] pt-4 mb-2">
                  <p className="text-[25px] font-semibold mb-6">
                    TERMS AND CONDITIONS
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    1. Cab Office will be fully responsible for serving a Job
                    once it has been booked or assigned on website in result of
                    settings, failures or delays in job will result into
                    termination of relationship between minicaberz and partner
                    Cab Office.
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    2. Cab Office will ensure accurate settings (waiting times,
                    freezing and rates) on website all the times for smooth Job
                    handling. Same minicaberz fee will be applicable in case of
                    failures and delays in assigned jobs.
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    3. 10% fee of total job amount will be charged by minicaberz
                    in every job booked or assigned (Cash and Account) to cab
                    office on monthly basis and will be payable within 7 days of
                    Invoice
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    4. Minicaberz is trading as minicaberz is not a
                    licensed private hire operator. minicaberz services are
                    acting as a Booking Agent.
                  </p>
                  <p className="text-[16px] font-normal pl-2">
                    5. All Standard Contracts and Payment terms are included as
                    per UK law.
                  </p>
                  <p className="border-t-2 border-gray-400 mt-5 mb-5"></p>
                  <div className="flex items-center mb-2">
                    <input
                      className="ml-2 mr-3"
                      type="checkbox"
                      name="agreeTerms"
                      checked={formData.agreeTerms}
                      onChange={handleCheckboxChange}
                    />
                    <p className="text-[16px]">
                      <span className="font-semibold">I agree</span> to be bound
                      by the terms and conditions.
                    </p>
                  </div>
                  <div className="flex items-center mb-6">
                    <input
                      className="ml-2 mr-3"
                      type="checkbox"
                      name="agreeCreditCheck"
                      checked={formData.agreeCreditCheck}
                      onChange={handleCheckboxChange}
                    />
                    <p className="text-[16px]">
                      <span className="font-semibold">I agree</span> that this
                      application form may be used as part of a credit check
                      undertaken by minicaberz and/or their nominated agents.
                    </p>
                  </div>
                </div>

                <div className="flex w-full">
                  <button
                    type="submit"
                    className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 rounded py-2 w-full transition duration-150 ease-in"
                  >
                    <span className="mr-2 uppercase">Register</span>
                    <span>
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </button>
                </div>
              </form>
            </div>
            <div className="flex justify-center items-center mt-6">
              <a
                href="#"
                target="_blank"
                className="inline-flex items-center font-bold text-blue-500 hover:text-blue-700 text-xs text-center"
              >
                <span>
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 4H18C19.1046 4 20 4.89543 20 6V18C20 19.1046 19.1046 20 18 20H15M11 16L15 12M15 12L11 8M15 12H3"
                      stroke="#5D86E5"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
               <Link to={'/login-cab'}>
                <span className="ml-2">Already have an account?</span>
                </Link>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

