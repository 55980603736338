import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import UpperNavbar from '../Components/UpperNavbar'
import ChauffeurSec1 from '../Components/ChauffeurSec1'
import ChauffeurSec2 from '../Components/ChauffeurSec2'

export default function Chauffeur() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='relative p-[0.1px]'>
      <UpperNavbar />
      <Navbar />
      <ChauffeurSec1 />  
      <ChauffeurSec2 />
      <Footer />
    </div>
  )
}

