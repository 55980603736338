import React from 'react'
import UpperNavbar from '../Components/UpperNavbar'
import Navbar from '../Components/Navbar'
import { NavLink } from 'react-router-dom'
import Footer from '../Components/Footer'

export default function SiteMap() {
  return (
    <div className='w-[100%] p-[0.1px]'>
      <UpperNavbar />
      <Navbar />
      <div className="w-[100%] pt-[100px] pb-10">
        <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto">
        <p className='text-[35px] font-bold'>Minicaberz Sitemap</p>
        <div className="w-[100%] flex flex-wrap">
            <div className="w-[95%] sm:w-[50%] lg:w-[33%] mb-3">
            <p className='text-[24px] font-semibold mt-3'>Header Navigation</p>
            <NavLink to={'/'}>
            <p className='text-[18px] mt-3 text-[#337AB7] underline'>
                Home
            </p>
            </NavLink>
            <NavLink to={'/about'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                About
            </p>
            </NavLink>
            <NavLink to={"/airport-transfer"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Airport Transfer
            </p>
            </NavLink>
            <NavLink to={"/our-cars"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Our Fleet
            </p>
            </NavLink>
            </div>
            <div className="w-[95%] sm:w-[50%] lg:w-[33%] mb-3">
            <p className='text-[24px] font-semibold mt-3'>Airport Transfer</p>
            <NavLink to={'/airport-transfer/heathrow-airport'}>
            <p className='text-[18px] mt-3 text-[#337AB7] underline'>
                Heathrow Airport Transfer
            </p>
            </NavLink>
            <NavLink to={'/airport-transfer/gatwick-airport'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Gatwick Airport Transfer
            </p>
            </NavLink>
            <NavLink to={"/airport-transfer/luton-airport"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Luton Airport Transfer
            </p>
            </NavLink>
            <NavLink to={"/airport-transfer/londoncity-airport"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                London City Airport Transfer
            </p>
            </NavLink>
            <NavLink to={"/airport-transfer/stansted-airport"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Stansted Airport Transfer
            </p>
            </NavLink>
            <NavLink to={"/airport-transfer/southend-airport"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Southend Airport Transfer
            </p>
            </NavLink>
            </div>
            <div className="w-[95%] sm:w-[50%] lg:w-[33%] mb-3">
            <p className='text-[24px] font-semibold mt-3'>Passenger Navigation</p>
            <NavLink to={'/login-passenger'}>
            <p className='text-[18px] mt-3 text-[#337AB7] underline'>
                Login
            </p>
            </NavLink>
            <NavLink to={'/signup-passenger'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Sign Up
            </p>
            </NavLink>
            <NavLink to={"/"}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Book Now
            </p>
            </NavLink>
            
            </div>
        
        {/* <div className="w-[100%] flex mt-3"> */}
            <div className="w-[95%] sm:w-[50%] lg:w-[33%] mb-3">
            <p className='text-[24px] font-semibold mt-3'>Service Categories</p>
            <NavLink to={'/airport-transfer'}>
            <p className='text-[18px] mt-3 text-[#337AB7] underline'>
                Airpor Transfer
            </p>
            </NavLink>
            <NavLink to={'/taxi-service'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Taxi Service
            </p>
            </NavLink>
            <NavLink to={'/station-transfer'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Station Transfer
            </p>
            </NavLink>
            <NavLink to={'/executive-transfer'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Executive Airport Transfer
            </p>
            </NavLink>
            <NavLink to={'/sight-seeing'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Sight Seeing
            </p>
            </NavLink>
            <NavLink to={'/cruise-transfer'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Cruise Port Transfer
            </p>
            </NavLink>
            </div>
            <div className="w-[95%] sm:w-[50%] lg:w-[33%] mb-3">
            <p className='text-[24px] font-semibold mt-3'>Useful Links</p>
            <NavLink to={'/privacy'}>
            <p className='text-[18px] mt-3 text-[#337AB7] underline'>
                Privacy Policy
            </p>
            </NavLink>
            <NavLink to={'/terms-condition'}>
            <p className='text-[18px] mt-1 text-[#337AB7] underline'>
                Terms & Conditions
            </p>
            </NavLink>
            
            </div>
        {/* </div> */}
        </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

