import React from "react";
import chauffeurImg1 from "./Images/Heathrow-Airport-Chauffeur-Services.jpg";
import CarImg1 from "./Images/sclass.jpg"
import CarImg2 from "./Images/vclass.jpg"
import CarImg3 from "./Images/eclass.jpg"
import CarImg4 from "./Images/bmw.jpg"
import CarImg5 from "./Images/buisness.jpg"
import CarImg6 from "./Images/img5.jpg"
import CarImg7 from "./Images/hotel-transfer.webp"
import { NavLink } from "react-router-dom";

const ChauffeurSec2 = () => {
  return (
    <div className="bg-[#F3F4F6] py-10">
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto">
        {/* Header Section */}
        <header className="mb-10 flex justify-between">
          <div className="w-[60%]">
            <h1 className="text-[35px] font-bold mb-4">
              Chauffeur Service for Ultimate Comfort
            </h1>
            <p className="text-[18px]">
              Welcome to MiniCaberz Chauffeur Service, where luxury meets
              reliability. Whether you’re traveling for business, heading to a
              special event, or simply seeking a stress-free journey, our
              premium chauffeur-driven cars ensure you arrive in style and
              comfort.
            </p>
            <p className="text-[18px] mt-3">
              Our professional Chauffeur Services
              are designed to offer a seamless, comfortable, and timely journey,
              whether you’re traveling for business or leisure. Our licensed
              chauffeurs are experienced in navigating the busy routes of
              London, ensuring you reach your destination with ease and
              sophistication. We provide a fleet of luxury vehicles, from
              executive sedans to spacious SUVs, all equipped with modern
              amenities to make your ride relaxing and enjoyable. Choose our
              chauffeur services for a hassle-free airport transfer
              experience that’s second to none. Book now and elevate your travel
              with unparalleled service, safety, and punctuality.
            </p>
          </div>
          <div className="w-[35%] flex justify-end">
            <img src={chauffeurImg1} className="w-[80%]" alt="" />
          </div>
        </header>

        <section class="pt-10 lg:pt-[10px] pb-5">
            <p className="text-center text-[35px] font-bold mb-6">Our Executive Cars</p>
            <div class="w-[100%]">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg1} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          S Class Mercedes
                        </a>
                      </h3>
                      
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg2} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          V Class Mercedes
                        </a>
                      </h3>
                      
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg3} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          E Class Mercedes
                        </a>
                      </h3>
                     
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg4} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          BMW
                        </a>
                      </h3>
                      
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </section>

        {/* Why Choose Section */}
        

        

        {/* Call to Action */}
      </div>
        <section className="text-center sec2_img pt-10 pb-10">
            <div className="w-[900px] bg-white rounded-md pt-3 pb-3 m-auto">

          <h2 className="text-[35px] font-bold text-gray-800 mb-4">
            Book Your Chauffeur Service Today!
          </h2>
          <p className="text-gray-600 mb-6 text-[18px]">
            Experience the ultimate chauffeur-driven journey with MiniCaberz.
          </p>
          <div className="flex justify-center space-x-4">
            <a
              href="tel:+442080508797 "
              className="bg-[#112759] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#112759ee] transition-colors"
              >
              Call Us Now
            </a>
            <NavLink to={"/"} className="bg-[#112759] text-white px-6 py-3 rounded-lg font-medium hover:bg-[#112759ee] transition-colors">
            <a
              href=""
              
              >
              Book Online
            </a>
            </NavLink>
          </div>
                </div>
        </section>
        <section class="pt-10 lg:pt-[40px] pb-5 w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto">
            <p className="text-center text-[35px] font-bold mb-6">Our Chauffeur Services</p>
            <div class="w-[100%]">
              <div class="flex flex-wrap -mx-4">
               
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg5} alt="image" class="w-full md:h-[40vh]" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          Business Chauffeur
                        </a>
                      </h3>
                      <p className="text-[18px]">We offers executive business chauffeur services across Airports in the UK. We operate across your nearest destination.</p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg6} alt="image" class="w-full md:h-[40vh]" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          Event Chauffeur
                        </a>
                      </h3>
                      <p className="text-[18px]">We offer chauffeur service for all types of events, whether it is a wedding, sporting, or special occasion like an anniversary.</p>
                     
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 lg:w-1/3 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg7} alt="image" class="w-full md:h-[40vh]" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-6 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-2
                        block
                        hover:text-primary
                        "
                        >
                          Sight Seeing
                        </a>
                      </h3>
                      <p className="text-[18px]">
                      If You are coming to London very first time, book with minicaber for the London sight seeing and attractive places with us.
                      </p>
                      
                    </div>
                  </div>
                </div>
                
                
              </div>
            </div>
          </section>
        <section className="pt-10 mb-10 w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto">
          <h2 className="text-[35px] font-bold text-gray-800 mb-6 text-center">
            Why Choose Our Chauffeur Service?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Luxury Fleet of Vehicles",
                description:
                  "Travel in top-tier vehicles equipped with the latest amenities for a smooth and relaxing ride.",
              },
              {
                title: "Professional & Experienced Chauffeurs",
                description:
                  "Our chauffeurs are courteous, knowledgeable, and highly trained to provide the highest level of service.",
              },
              {
                title: "Punctual and Reliable",
                description:
                  "With MiniCaberz, you’ll never have to worry about delays or missed schedules.",
              },
              {
                title: "Customizable Rides",
                description:
                  "Tailor your ride to your specific needs, whether it’s an airport transfer, corporate meeting, or sightseeing tour.",
              },
              {
                title: "Competitive Pricing",
                description:
                  "Experience luxury at affordable rates. Our transparent pricing ensures no hidden charges.",
              },
              {
                title: "24/7 Availability",
                description:
                  "We’re available anytime, ensuring your journey is always on time and stress-free.",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="p-6 bg-white rounded-lg shadow hover:shadow-lg transition-shadow duration-200"
              >
                <h3 className="text-xl font-semibold text-gray-800 mb-2">
                  {item.title}
                </h3>
                <p className="text-gray-600">{item.description}</p>
              </div>
            ))}
          </div>
        </section>
    </div>
  );
};

export default ChauffeurSec2;

