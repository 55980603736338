import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import UpperNavbar from '../Components/UpperNavbar'
import TermsSec1 from '../Components/TermsSec1'

export default function TermsCondition() {
    useEffect(() => {
        // Scroll to the top when this component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='relative p-[0.1px]'>
      <UpperNavbar />
      <Navbar />
      <TermsSec1 />
      <Footer />
    </div>
  )
}

