import React, { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import UpperNavbar from "./UpperNavbar";
import headimg1 from "../Components/Images/stationtransfer.jpg";
import { GoDotFill } from "react-icons/go";
import CarImg1 from "./Images/9.webp";
import CarImg2 from "./Images/e.webp";
import CarImg3 from "./Images/tour.jpg";
import CarImg4 from "./Images/g.jpg";
import CarImg5 from "./Images/g.jpg";
import { Link } from "react-router-dom";
import { FaMoneyBill } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { FaBookOpenReader } from "react-icons/fa6";
import { FaCarAlt } from "react-icons/fa";


export default function StationTransfer() {
  useEffect(() => {
    // Scroll to the top when this component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-[100%] relative bg-[#F3F4F6]">
      <UpperNavbar />
      <Navbar />
      <div className="pt-[75px] w-[100%]">
        <div className="w-[100%] h-[40vh] heathrow-bg relative">
          <div className="absolute top-0 left-0 h-[100%] w-[100%] flex items-center justify-center bg-[#000000ad]">
            <p className="text-[50px] font-semibold text-white">
              Station Transfer
            </p>
          </div>
        </div>
      </div>
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] m-auto pt-10 pb-10">
        <div className="w-[100%] flex justify-center lg:justify-between items-center flex-wrap">
          <div className="w-[100%] sm:w-[95%] lg:w-[60%]">
            <p className="text-[35px] font-semibold">
            Reliable Station Transfer with Minicaberz
            </p>
            <p className="text-[18px] mt-3">
            Traveling to or from the station? Minicaberz provides fast, reliable, and affordable station transfer taxi services designed to make your journey seamless. Whether you're heading to a major railway hub or a smaller local station, we ensure a stress-free experience every time.
            </p>
            <p className="text-[18px] mt-3">
            Say goodbye to the hassle of last-minute rides or unreliable services. Book your station transfer taxi with Minicaberz now and experience the convenience of on-time arrivals, excellent customer support, and affordable rates.
            </p>
            <p className="text-[18px] mt-3">
            Whether you need a one-way station drop-off or a return journey, Minicabber has you covered. Traveling late at night or during peak hours? Our 24/7 service ensures availability when you need it most.
            </p>
          </div>
          <div className="w-[100%] sm:w-[90%] mt-10 lg:mt-0 lg:w-[35%] h-max">
            <img src={headimg1} className="w-[100%] h-max" alt="" />
          </div>
        </div>

        
      <div className="pt-10 pb-10">
        <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px] mx-auto p-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaStar className="text-[35px]" />
                <h2 className="text-lg font-semibold">Reliable</h2>
                <p className="mt-2 text-gray-600 text-center">
                  We monitor flight schedules to adjust your pick-up time in
                  case of delays or early arrivals.
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaMoneyBill className="text-[35px]" />
                <h2 className="text-lg font-semibold">No Surge</h2>
                <p className="mt-2 text-gray-600 text-center">
                  We are not increasing the fares even in traffic routes , you
                  will pay the fare on what you have booked your journey.
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaBookOpenReader className="text-[35px]" />
                <h2 className="text-lg font-semibold">Comfortable Vehicles</h2>
                <p className="mt-2 text-gray-600 text-center">
                  From standard cars to luxury options, we cater to individuals,
                  families, and groups.
                </p>
              </div>
            </div>
            <div className="bg-white shadow-md rounded-lg overflow-hidden">
              <div className="p-4 flex flex-col items-center justify-center">
                <FaCarAlt className="text-[35px]" />
                <h2 className="text-lg font-semibold">Professional Drivers</h2>
                <p className="mt-2 text-gray-600 text-center">
                  All drivers are licensed and experienced, ensuring a safe and
                  comfortable journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="w-[100%]">
          <p className="text-[35px] font-bold text-center mt-6">Our Fleet</p>

          <section class="pt-10 lg:pt-[60px] pb-5">
            <div class="w-[100%]">
              <div class="flex flex-wrap -mx-4">
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg1} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          Saloon Car
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        4 Passengers 2 Bags 2 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg2} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          Estate Car
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        4 Passengers 4 Bags 2 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg3} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          MPV Van
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        6 Passengers 4 Bags 4 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg4} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          8 SEATER
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        7 Passengers 6 Bags 6 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2 xl:w-1/3 px-4">
                  <div class="bg-white rounded-lg overflow-hidden mb-10">
                    <img src={CarImg5} alt="image" class="w-full" />
                    <div class="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                      <h3>
                        <a
                          href="javascript:void(0)"
                          class="
                        font-semibold
                        text-dark text-xl
                        sm:text-[22px]
                        md:text-xl
                        lg:text-[22px]
                        xl:text-xl
                        2xl:text-[22px]
                        mb-4
                        block
                        hover:text-primary
                        "
                        >
                          9 SEATER
                        </a>
                      </h3>
                      <p class="text-[18px] text-body-color leading-relaxed mb-3">
                        8 Passengers 6 Bags 6 Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="w-[100%] pt-5 pb-5 text-center call-bg">
          <p class="text-[40px] font-semibold text-white">
            CALL 020 8050 8797 OR BOOK ONLINE
          </p>
          <p class="text-[18px] mt-6 text-white">
            Always Pre Book Your Airport Trasnfer Taxi To Get Hassle Free
            Service
          </p>
          <Link to={"/"}>
            <button class="bg-[white] rounded-md pl-6 pr-6 pt-2 pb-2 mt-3">
              BOOK NOW
            </button>
          </Link>
        </div>
        <section class="w-[100%] pb-8">
          {/* <div class="section4-container"> */}
          <p class="text-[35px] text-center font-bold mt-8">
            Why Choose minicaberz for Station Transfer
          </p>
          <div class="flex flex-wrap justify-center md:justify-between mt-8">
            <div class="w-[100%] sm:w-[95%] md:w-[48%]">
              <p class="text-[18px]">
                Looking for a dependable, affordable taxi service in London?
                minicaberz offers
                <span className="font-semibold">
                  24/7 airport transfers
                </span>{" "}
                and
                <span className="font-semibold">minicab services</span>
                across London, including Heathrow, Gatwick, Stansted, and other
                major airports. With minicaberz, you get safe, comfortable rides
                at fixed rates—no hidden charges!
              </p>
              <p class="text-[18px] mt-4">
                Ready to ride?
                <span className="font-semibold">
                  Book your London airport taxi with minicaberz now!
                </span>
                Whether it’s for business, a family trip, or a quick transfer,
                we’ve got you covered. Visit minicaberz.co.uk to schedule your
                next ride. We provide round-the-clock airport transfer services
                across all London airports, ensuring you reach your destination
                on time, day or night.
              </p>
            </div>
            <div class="w-[100%] sm:w-[95%] md:w-[48%]">
              <p class="text-[18px]">
                <span className="font-semibold">Professional Drivers:</span> Our
                experienced, courteous drivers are ready to pick you up any
                time, day or night.
              </p>
              <p class="text-[18px] mt-3">
                <span className="font-semibold">Easy Online Booking:</span>{" "}
                Simply book online for a smooth experience—no waiting, no
                hassle.
              </p>
              <p class="text-[18px] mt-3">
                <span className="font-semibold">Affordable Rates:</span> We
                believe in transparency; all fares are fixed and affordable.
              </p>
              <p class="text-[18px] mt-3">
                <span className="font-semibold">Reliable & Punctual:</span>{" "}
                Trust minicaberz to get you to your destination on time, every
                time.
              </p>
            </div>
          </div>
          {/* </div> */}
        </section>
      </div>
      <Footer />
    </div>
  );
}

