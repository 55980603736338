import React,{useEffect} from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import UpperNavbar from '../Components/UpperNavbar'
import PrivacySec1 from '../Components/PrivacySec1'

export default function PrivacyPolicy() {
    useEffect(() => {
        // Scroll to the top when this component mounts
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='relative p-[0.1px]'>
      <UpperNavbar />
      <Navbar />
      <PrivacySec1 />
      <Footer />
    </div>
  )
}

