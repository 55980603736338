import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import briefcasImg from "../Images/briefcase.png";
import briefcasImg2 from "../Images/job.png";
import briefcasImg3 from "../Images/recycle-bin.png";
import audio1 from '../audio/audio1.mp3'
import { BsThreeDotsVertical } from "react-icons/bs";


function CabofficeDashboard() {
  const [cabOfficer, setcabOfficer] = useState({});
  const [jobs, setJobs] = useState([]);
  const previousJobsCount = useRef(0); // useRef to keep track of previous job count
  const [audio, setAudio] = useState(null);
  const [interactionHappened, setInteractionHappened] = useState(false);
  const navigate = useNavigate();
  const [dropdown1,setDropdown1]= useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("cabofficeToken");

      try {
        const response = await axios.get(
          "https://api.minicaberz.com/api/caboffice/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setcabOfficer(response.data.cabOffice);
        console.log(response.data.cabOffice);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchData();

    // Add event listener to detect user interaction
    const handleUserInteraction = () => {
      setInteractionHappened(true);
      setAudio(new Audio(audio1)); // Replace with your sound file path
      window.removeEventListener('click', handleUserInteraction);
    };

    window.addEventListener('click', handleUserInteraction);

    return () => {
      window.removeEventListener('click', handleUserInteraction);
    };
  }, []);

  const fetchJobs = async () => {
    try {
      const token = localStorage.getItem("cabofficeToken");
      const response = await axios.get(
        "https://api.minicaberz.com/api/caboffice/jobs",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      const currentJobsCount = response.data.jobs.length;

      // If new job is added, trigger beep sound if interaction happened
      if (currentJobsCount > previousJobsCount.current && interactionHappened && audio) {
        console.log(currentJobsCount, previousJobsCount.current);
        
        audio.play().catch(error => console.error('Audio play failed:', error));
        console.log(audio,'audio');
      }

      setJobs(response.data.jobs);
      previousJobsCount.current = currentJobsCount; // Update the ref value

      console.log(response.data.jobs, "updatejob");

    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  };

  useEffect(() => {
    fetchJobs();
    const intervalId = setInterval(fetchJobs, 1000);

    return () => clearInterval(intervalId);
  }, [interactionHappened, audio]);



  const token = localStorage.getItem("cabofficeToken");
  const handleJobAction = async (jobId, action) => {
    try {
      
      await axios.post(
        `https://api.minicaberz.com/api/caboffice/jobs/${jobId}/actions`,
        { action },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      fetchJobs();
      setDropdown1(false)
    } catch (error) {
      console.error("Error handling job action:", error);
    }
  };

  const getActionButtons = (job) => {
    switch (job.status) {
      case 'Approved':
        return (
          <>
            <button
              className="bg-blue-500 p-1 rounded-lg text-[14px] text-white mr-2 ml-2"
              onClick={() => handleJobAction(job._id, "accept")}
            >
              Accept
            </button>
            <button
              className="bg-red-500 p-1 rounded-lg text-[14px] mt-2 text-white ml-2 mr-2"
              onClick={() => handleJobAction(job._id, "reject")}
            >
              Reject
            </button>
          </>
        );
      case 'Accepted':
        return (
          <button
            className="bg-green-500 p-1 rounded-lg text-[14px] text-white ml-2 mr-2"
            onClick={() => handleJobAction(job._id, "complete")}
          >
            Complete
          </button>
        );
      case 'Completed':
        return <span className="text-gray-500">Completed</span>;
      default:
        return null;
    }
  };
  
  const [filter, setFilter] = useState("new"); // State for filter
  const [filteredJobs, setFilteredJobs] = useState([]);

  const newJobCount = jobs?.filter((job) => (job.status === "Pending" || job.status === "Approved") && !job.rejectedBy.includes(cabOfficer._id)).length || 0;
  const activeJobCount = jobs?.filter((job) => job.status === "Accepted").length || 0;
  const completedJobCount = jobs?.filter((job) => job.status === "Completed").length || 0;
  const cancelledJobCount = jobs?.filter((job) => job.rejectedBy.includes(cabOfficer._id)).length || 0;
  // console.log(jobs,'rejecteddd');
  
  useEffect(() => {
    // Filter jobs based on the selected filter
    console.log(jobs,'filterrrr')
    if (jobs) {
      const filtered = jobs.filter((job) => {
        // if (filter === "all") return job;
        if (filter === "new") return (job.status === "Pending" || job.status === "Approved") && !job.rejectedBy.includes(cabOfficer._id);
        if (filter === "active") return job.status === "Accepted";
        if (filter === "completed") return job.status === "Completed";
        if (filter === "cancelled") return job.rejectedBy.includes(cabOfficer._id);
        return true;
      });
      setFilteredJobs(filtered);
    }
  }, [filter, jobs]);
  const handleDrop = (jobId) => {
    if (dropdown1 === jobId) {
      setDropdown1(null);
    } else {
      setDropdown1(jobId);
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // If clicked outside the dropdown, close it
        handleDrop(null);
      }
    }

    // Add event listener for mousedown (or click) event
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Cleanup the event listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdown1]);
  const truncateText = (text, wordLimit)=> {
    const words = text.split('');
    console.log(words,'hello');
    
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join('') + '...';
    }
    return text;
  }
  return (
    <div className="flex flex-col relative pt-[75px] bg-[#E9E9EB]">
      {/* <CabOfficeNavbar /> */}
      <div className="w-[100%] pb-10">
        <div className="w-[100%] flex justify-between items-center pt-3 pb-3 pl-6 pr-6">
          <p className="text-[16px] font-semibold text-white"></p>
          
        </div>
        <div className="w-[100%] flex justify-between pt-8 pb-20 pl-6 pr-6">
          {/* Your stats cards here */}
          <div onClick={() => setFilter("new")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">NEW</div>
              <p className="text-[20px] mt-1 font-[500]">{newJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#67CDEF] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("active")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">ACTIVE</div>
              <p className="text-[20px] mt-1 font-[500]">{activeJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E8613C] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("completed")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">COMPLETED</div>
              <p className="text-[20px] mt-1 font-[500]">{completedJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#F6D500] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg2} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("cancelled")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">REJECTED</div>
              <p className="text-[20px] mt-1 font-[500]">{cancelledJobCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E6335B] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg3} alt="" />
            </div>
          </div>
        </div>
        <div>
          <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[-65px] ml-[2.5%] bg-[white] border">
            <h2 className="text-2xl font-semibold ml-4 mb-4">Jobs</h2>
            <table className="bg-white border border-gray-200 w-full">
              <thead>
                <tr className="bg-[#2649AD] text-white text-[14px] font-semibold text-left whitespace-nowrap">
                  <th className="py-1 px-4 text-[14px] border-x-2">STATUS</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">TIME</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">PICKUP</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">VIA</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">DROP OFF</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">ROUTE</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">CARTYPE</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">MILES</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">FARE</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">PHONE</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">Payment_Type</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">MESSAGE</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">NAME</th>
                  <th className="py-1 px-4 text-[14px] border-x-2">ACTIONS</th>
                </tr>
              </thead>
              <tbody className="">
                {filteredJobs.map((job,index) => (
                  <tr key={job._id} className={`text-left whitespace-nowrap  ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                    <td className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{job.status === 'Approved' ? "Inprocessing" : job.status }</td>
                    <td className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">
                      {job.time} {formatDate(job.date)}
                    </td>
                    <td title={job.pickupLocation} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{truncateText(job.pickupLocation,17)}</td>
                    <td title={job.viaLocation} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{truncateText(job.viaLocation,17)}</td>
                    <td title={job.dropLocation} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{truncateText(job.dropLocation,17)}</td>
                    <td title={job.route} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{job.route}</td>
                    <td title={job.carType} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{job.carType}</td>
                    <td title={job.distance} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{job.distance} miles</td>
                    <td title={job.fare} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">£ {parseFloat(job.fare).toFixed(2)}</td>
                    <td title={job.phoneNumber} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{job.phoneNumber}</td>
                    <td title={job.payment_type} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{job.payment_type}</td>
                    <td title={job.message} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{truncateText(job.message,17)}</td>
                    <td title={job.name} className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2">{truncateText(job.name,17)}</td>
                    <td className="py-1 w-[max-content] px-4 text-[14px] border-x-2 border-b-2 relative">
                    <button
                        onClick={() => handleDrop(job._id)}
                        className="text-[16px] text-center hover:editdrop p-1 hover:rounded-lg hover:bg-slate-100"
                      >
                        <BsThreeDotsVertical />
                      </button>
                      {dropdown1 === job._id && (<div ref={dropdownRef}
                        className="editdrop absolute top-[40px] right-[40px] z-30 rounded-lg pt-2 pr-1 pl-1 pb-2 w-[150px] h-[max-content] bg-white flex flex-col justify-start"
                      >
                      {getActionButtons(job)}
                      </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CabofficeDashboard;

