import React from "react";
import { BiTaxi } from "react-icons/bi";
import { GiCommercialAirplane } from "react-icons/gi";
import { IoBriefcase } from "react-icons/io5";
import { FaHotel } from "react-icons/fa";
import { FaSchool, FaTrain } from "react-icons/fa";
import { RiCarFill } from "react-icons/ri";
import { MdOutlineTour, MdDirectionsBoat } from "react-icons/md";
import { NavLink } from "react-router-dom";

export default function Services() {
  return (
    <div className="w-[100%] pt-7 pb-12 flex items-center justify-center">
      <div className="w-[95%] sm:w-[90%] 2xl:w-[1450px]">
        <p className="text-[35px] text-center font-bold">Our Services</p>
        <div
          id="services"
          class="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white"
        >
          <div class="w-[100%]">
            <div class="flex flex-wrap flex-row -mx-4 text-center">
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
              >
                <NavLink to={"/taxi-service"}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <BiTaxi className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Taxi Service
                  </h3>
                  <p>
                    Minicaberz is dedicated to being there when you need us.
                    With our extensive fleet of taxis, we are your go-to choice
                    for a reliable and affordable "Local Taxi" service. Whether
                    you're heading to work, a night out, or a special event, our
                    drivers are just a call away.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".1s"
              >
                <NavLink to={"/airport-transfer"}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <GiCommercialAirplane className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Airport Taxi Service
                  </h3>
                  <p>
                    Traveling to or from the London Airports? Look no further
                    than Minicabers. Our "Airport Transfer Taxi" service is
                    punctual,reliable, and offers competitive rates for all
                    major airports in London. We ensure you get there on time
                    and stress-free.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={'/executive-transfer'}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <IoBriefcase className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Executive Airport Transfer
                  </h3>
                  <p>
                    Our cab company is synonymous with reliability, quality, and
                    affordability. Whether you need a cab for a short trip or a
                    longer commute, Minicaberz is the cab company you can
                    trust.Whether you need a cab for a short trip or a longer
                    commute, Minicabers is the cab company you can trust.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={'/hotel-transfer'}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    {/* <IoBriefcase className='text-[35px]' /> */}
                    <FaHotel className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Hotel Transfer
                  </h3>
                  <p>
                    Our hospital transfer service is ideal for those needing
                    transport for medical appointment. Our drivers ensure timely
                    pickups and drop-offs, accommodating any special
                    requirements to make your trip comfortable and stress-free.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={'/school-run'}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <FaSchool className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    School Run
                  </h3>
                  <p>
                    Minicaberz offers dedicated school run service with
                    dedicated and professional drivers. Whether you need a cab
                    for a short trip or a longer commute,. Who will be punctual
                    and always on time. Our cab company is synonymous with
                    reliability.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={"/station-transfer"}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <FaTrain className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Station Transfer
                  </h3>
                  <p>
                    Getting to and from London’s railway stations, such as
                    King’s Cross, Paddington, and Victoria has never been
                    easier. Our station transfer service provides a quick and
                    reliable way to ensure you don’t miss a train .
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={'/wedding'}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <RiCarFill className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Wedding Car Hire
                  </h3>
                  <p>
                    Choose from a selection of elegant, well-maintained vehicles
                    to match the tone of your wedding. Our drivers are punctual
                    and professional, ensuring a flawless journey so that you
                    can focus on the moments that matter most.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={'/sight-seeing'}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <MdOutlineTour className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Sight Seeing
                  </h3>
                  <p>
                    If You are coming to London very first time, book with
                    minicaber for the London sight seeing and attractive places
                    with us.Our company is synonymous with reliability and
                    affordability. Whether you need a cab for a short trip or a
                    longer, Minicaberz is the cab company you can trust.
                  </p>
                </div>
                </NavLink>
              </div>
              <div
                class="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <NavLink to={"/cruise-transfer"}>
                <div class="py-8 px-12 mb-12 bg-gray-50 border-b border-gray-100 hover:bg-[#112759] hover:text-white transform transition duration-300 ease-in-out hover:-translate-y-2">
                  <div class="inline-block mb-4">
                    <MdDirectionsBoat className="text-[35px]" />
                  </div>
                  <h3 class="text-lg leading-normal mb-2 font-semibold">
                    Cruise Port Transfer
                  </h3>
                  <p class="">
                    Minicaberz offers dedicated cruise port transfer service
                    with dedicated and professional drivers. Whether you need a
                    cab for a short trip or a longer commute,. Who will be
                    punctual and always on time. Our cab company is synonymous
                    with reliability, quality, and affordability.
                  </p>
                </div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

