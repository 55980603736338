import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import briefcasImg from "../Images/briefcase.png";
import briefcasImg2 from "../Images/job.png";
import briefcasImg3 from "../Images/recycle-bin.png";
import CabOfficerDetailsModal from "./CabOfficerDetailsModal";

function AdminCabOffice() {
  const [adminData, setAdminData] = useState([]);
  const [cabOfficers, setCabOfficers] = useState([]);
  const [selectedOfficer, setSelectedOfficer] = useState(null); // State to hold selected officer data
  const [dropdown, setDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const token1 = localStorage.getItem("adminToken");
      const adminResponse = await axios.get(
        "https://api.minicaberz.com/api/admin/getAdmin",
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      const cabOfficeResponse = await axios.get(
        "https://api.minicaberz.com/api/admin/getCabofficer",
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      setAdminData(adminResponse.data);
      setCabOfficers(cabOfficeResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error.response?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("adminToken");
    navigate("/admin/login");
  };

  const handleDetailsClick = (officer) => {
    setSelectedOfficer(officer);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedOfficer(null);
  };

  const handleOfficerSave = async (updatedOfficer) => {
    try {
      const token1 = localStorage.getItem("adminToken");
      console.log(updatedOfficer, "updatedofficer");

      await axios.put(
        `https://api.minicaberz.com/api/admin/updateCabofficer/${updatedOfficer._id}`,
        updatedOfficer,
        {
          headers: {
            Authorization: `Bearer ${token1}`,
          },
        }
      );
      // Update the cab officer in the list with the new data
      setCabOfficers((prev) =>
        prev.map((officer) =>
          officer._id === updatedOfficer._id ? updatedOfficer : officer
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error updating cab officer:", error.response?.data);
    }
  };
  const handleApproval = async (id, action) => {
    try {
      const token1 = localStorage.getItem("adminToken");
      await axios.post(
        "https://api.minicaberz.com/api/admin/approveCabOffice",
        { cabOfficeId: id, action },
        {
          headers: { Authorization: `Bearer ${token1}` },
        }
      );
      fetchData(); // Refresh data after approval/rejection
    } catch (error) {
      console.error("Error updating cab office status:", error.response?.data);
    }
  };
  
  const [filter, setFilter] = useState("New"); // State for filter
  const [filteredCab, setFilteredJobs] = useState([]);

  const newCabofficeCount =
    cabOfficers?.filter((caboffice) => caboffice.isApproved === "Pending")
      .length || 0;
  const approveCabofficeCount =
    cabOfficers?.filter((caboffice) => caboffice.isApproved === "Approved").length || 0;
  const rejectCabofficeCount =
    cabOfficers?.filter((caboffice) => caboffice.isApproved === "Rejected").length || 0;
  const allCabofficeCount =
    cabOfficers?.filter((caboffice) => caboffice).length || 0;

  useEffect(() => {
    // Filter jobs based on the selected filter
    if (cabOfficers) {
      const filtered = cabOfficers.filter((caboffice) => {
        if (filter === "New")
          return caboffice.isApproved === "Pending";
        if (filter === "Approved") return caboffice.isApproved === "Approved";
        if (filter === "Rejected") return caboffice.isApproved === "Rejected";
        if (filter === "All") return caboffice;
        return true;
      });
      setFilteredJobs(filtered);
    }
  }, [filter, cabOfficers]);
  // console.log(filteredJobs);
  
  return (
    <div className="flex flex-col h-[100vh] pt-[75px] w-[100%] bg-[#E9E9EB]">
      <div className="w-[100%]">
        <div className="w-[100%] flex justify-between items-center pt-3 pl-10 pr-6">
          <p className="text-[24px] font-semibold">
            CAB OFFICES
          </p>
          
        </div>
        <div className="w-[100%] flex justify-between pt-5 pb-20 pl-6 pr-6">
          <div onClick={() => setFilter("New")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">NEW</div>
              <p className="text-[20px] mt-1 font-[500]">{newCabofficeCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#67CDEF] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div onClick={() => setFilter("All")} className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="">
              <div className="text-[#8898aa] text-[14px]">ALL</div>
              <p className="text-[20px] mt-1 font-[500]">{allCabofficeCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E8613C] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg} alt="" />
            </div>
          </div>
          <div className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="" onClick={() => setFilter("Approved")}>
              <div className="text-[#8898aa] text-[14px]">APPROVED</div>
              <p className="text-[20px] mt-1 font-[500]">{approveCabofficeCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#F6D500] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg2} alt="" />
            </div>
          </div>
          <div className="w-[23%] cursor-pointer flex justify-between items-start p-4 mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform hover:scale-[1.02] transition duration-300">
            <div className="" onClick={() => setFilter("Rejected")}>
              <div className="text-[#8898aa] text-[14px]">REJECTED</div>
              <p className="text-[20px] mt-1 font-[500]">{rejectCabofficeCount}</p>
              <p className="text-[16px] text-[#A6B1BD] mt-1">
                See more details
              </p>
            </div>
            <div className="w-[60px] h-[60px] bg-[#E6335B] rounded-full flex items-center justify-center">
              <img className="w-[35px] breifImg" src={briefcasImg3} alt="" />
            </div>
          </div>
        </div>
        <div className="w-[95%] h-[62vh] custom-scrollbar rounded-xl pt-4 mt-[-65px] ml-[2.5%] bg-[white] border">
          <h2 className="text-2xl font-semibold ml-4 mb-4">{filter} Cab Offices</h2>
          <table className="w-[100%] bg-white border border-gray-200">
            <thead>
              <tr className="bg-[#2649AD] text-white text-left text-[14px] font-semibold">
                <th className="py-1 px-4 border-2 text-[14px]">NAME</th>
                <th className="py-1 px-4 border-2 text-[14px]">EMAIL ADDRESS</th>
                <th className="py-1 px-4 border-2 text-[14px]">PHONE NO</th>
                <th className="py-1 px-4 border-2 text-[14px]">FAX</th>
                <th className="py-1 px-4 border-2 text-[14px]">POST CODE</th>
                <th className="py-1 px-4 border-2 text-[14px]">STATUS</th>
                <th className="py-1 px-4 border-2 text-[14px]">DETAIL</th>
                <th className="py-1 px-4 border-2 text-[14px]">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredCab
                ? filteredCab.map((officer,index) => (
                    <tr key={officer._id} className={`text-left ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                      <>
                        <td className="py-1 px-4 border-2 text-[14px]">
                          {officer.company_name}
                        </td>
                        <td className="py-1 px-4 border-2 text-[14px]">
                          {officer.email_authController}
                        </td>
                        <td className="py-1 px-4 border-2 text-[14px]">
                          {officer.phone_caboffice}
                        </td>
                        <td className="py-1 px-4 border-2 text-[14px]">
                          {officer.buis_fax}
                        </td>
                        <td className="py-1 px-4 border-2 text-[14px]">
                          {officer.buis_postcode}
                        </td>
                        <td className="py-1 px-4 border-2 text-[14px]">{officer.isApproved}</td>
            

                        <td className="py-1 px-4 border-2 text-[14px]">
                            <button
                              onClick={() => handleDetailsClick(officer)}
                              className="text-white bg-blue-500 hover:bg-blue-700 px-4 py-1 rounded"
                            >
                              Details
                            </button>
                        </td>
                        <td className="py-1 px-4 border-2 text-[14px]">
              {(officer.isApproved === "Pending" || officer.isApproved === "Rejected") && (
                <button
                  className="text-white bg-green-500 hover:bg-green-700 px-4 py-1 rounded"
                  onClick={() => handleApproval(officer._id, "approve")}
                >
                  Approve
                </button>
              )}
              {officer.isApproved === "Approved" && (
                <button
                  className="text-white bg-red-500 hover:bg-red-700 px-4 py-1 rounded"
                  onClick={() => handleApproval(officer._id, "reject")}
                >
                  Reject
                </button>
              )}
            </td>
                      </>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
        </div>
      </div>
      {/* <AddFareForm /> */}
       {isModalOpen && selectedOfficer && (
         <CabOfficerDetailsModal
           officer={selectedOfficer}
           onClose={handleModalClose}
           onSave={handleOfficerSave}
         />
       )}
    </div>
  );
}

export default AdminCabOffice;

