import React from "react";
import Navbar from "./Navbar";
import UpperNavbar from "./UpperNavbar";
import Footer from "./Footer";

const ThankYouPage = () => {
  return (
    <div className="w-[100%] p-[0.1px] relative">
      <UpperNavbar />
      <Navbar />
      <div className="flex flex-col items-center justify-center h-[80vh] bg-gray-100">
        <h1 className="text-[50px] text-center  font-bold text-blue-600 mb-4">Thank You For Booking With Minicaberz!</h1>
        <p className="text-lg text-gray-700 mb-6">
        Your booking is confirmed, if you have any query regarding your booking call our helpline <span className="text-blue-800">02080508797</span>
        </p>
        <button
          onClick={() => window.location.assign("/")}
          className="px-4 py-2 bg-blue-600 text-white rounded-full"
        >
          Go to Home
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default ThankYouPage;

